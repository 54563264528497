<template>
    <div class="zt-page-content">
        <div class="zt-block flex flex-center" style="height:calc(100% - 40px)">
            <div class="box">
                <div class="contact flex" style="padding-top:50px">
                    <label>联系电话</label>
                    <span>19970936393</span>
                </div>
                <div class="contact flex">
                    <label>工作时间</label>
                    <div>
                        <div>周一到周五</div>
                        <div>08：30-11：30</div>
                        <div>13：30-18：00</div>
                    </div>
                </div>

                <div style="padding:50px 0 80px">
                    <img :src="STATIC_URL_PRE+'/design/placeholder/lianxipingtai.png'">
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    import { STATIC_URL_PRE } from '@/config';
    export default {
        components: {},
        data() {
            return {
                STATIC_URL_PRE,
            }
        },
        created() {
            
        },
        methods: {
            
        }
    }
</script>
<style scoped>
    .box .contact {
        width: 320px;
        margin: 10px auto 0;
    }
    .contact label {
        font-size: 24px;
        color: #404040;
        line-height: 32px;
        margin-right: 20px;
    }
    .contact span, .contact>div>div {
        font-size: 20px;
        color: #808080;
        line-height: 32px;
    }
</style>